<template>
  <div class="py-16">
    <div class="mb-10 lg:max-w-5xl md:mb-12">
      <h2
        class="mb-6 text-3xl font-bold leading-none tracking-tight md:text-5xl text-purple"
      >
        Professional Trading Centers
      </h2>
      <h2
        class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900  md:text-3xl"
      >
        Low latency for traders + enhanced rewards for delegators.
      </h2>
    </div>
  </div>

  <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-24 sm:gap-0 md:mx-auto">
    <div>
      <div class="flex">
        <div class="mr-4">
          <div class="flex items-center justify-center w-10 h-10 mb-3 rounded-full  bg-indigo-50">
            <svg
              class="w-8 h-8 text-deep-purple-accent-400"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              ></polygon>
            </svg>
          </div>
        </div>
        <p>
          Is it possible to achieve low-latency trading in decentralized finance? <span class="text-purple">YES!</span>
        </p>
      </div>
      <div class="ml-14">
        <p>
          Is it possible to provide multiple sources of rewards for Solana stakeholders? <span class="text-purple">YES!</span>
        </p>
      </div>
      <div class="ml-14">
        <p>
          Colocation programs for defi? <span class="text-purple">YES!</span>
        </p>
      </div>
      <div class="ml-14">
        <p>
          Our Professional Trading Centers create a unique ecosystem of colocated Solana validators, RPC servers, and trading servers to benefit traders and Solana stakeholders.
        </p>
      </div>
    </div>

    <div>
      <img class="mx-auto px-14" src="./../assets/solanalocals.webp" />
    </div>
  </div>

  <hr class="w-full my-6 border-gray-300" />

  <div>
    <h2 class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900 md:text-3xl uppercase">
      For Traders
    </h2>
    <div class="flex">
      <div class="mr-4">
        <div
          class="flex items-center justify-center w-10 h-10 mb-3 rounded-full  bg-indigo-50"
        >
          <svg
            class="w-8 h-8 text-deep-purple-accent-400"
            stroke="currentColor"
            viewBox="0 0 52 52"
          >
            <polygon
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              points="29 13 14 29 25 29 23 39 38 23 27 23"
            ></polygon>
          </svg>
        </div>
      </div>
      <p>
        You can trade with the lowest possible latency and priority routes for your transactions when well-staked validators, RPC nodes, and trading servers are running in the same data center.
      </p>
    </div>
    <div class="ml-14">
      <p>
        Up to 200ms improved read latency (compared to regular RPC nodes) due to fast hardware and data retransmissions from the validators. We use a federated validators network to aggregate the validators' cumulative stake.
      </p>
    </div>
    <div class="ml-14">
      <p>
        Transactions travel through the validators for better execution on the cluster. Soon, Solana will incorporate stake-based TX priority. According to their active stake weight, validators will receive a minimum percentage of block space. Your transactions will have priority over others.
      </p>
    </div>
    <div class="ml-14">
      <p>
        Take your trading to the ultimate level with Geyser plugins for real-time account streaming. Real-time data streaming plus improved read-latency allow you to see account changes within the current slot. Geyser plugins provide the fastest possible configuration for arbitrage and other trading strategies. See <a href="https://github.com/rpcpool/solana-geyser-park" target="_blank" class="text-purple">https://github.com/rpcpool/solana-geyser-park</a> for a list of supported plugins.
      </p>
    </div>
  </div>

  <hr class="w-full my-6 border-gray-300" />

  <div class="grid gap-24 lg:grid-cols-1 sm:mx-auto">
    <div class="flex flex-col justify-center">
      <h2
        class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900 md:text-3xl uppercase"
      >
        For Stakeholders
      </h2>
      <div class="flex">
        <div class="mr-4">
          <div
            class="flex items-center justify-center w-10 h-10 mb-3 rounded-full  bg-indigo-50"
          >
            <svg
              class="w-8 h-8 text-deep-purple-accent-400"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              ></polygon>
            </svg>
          </div>
        </div>
        <p>
          We can't offer improved performance to traders without the help of the stakeholders who delegate to our validators. The stakeholders are integral to each Professional Trading Center and deserve a share of the rewards.
        </p>
      </div>
      <div class="flex">
        <div class="ml-14">
          <p>
            Stakeholders in our ecosystem will receive multiple sources of rewards:
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="ml-14">
          <ul>
            <li>Traditional staking rewards.</li>
            <li>
              Enhancements from Trader Access Fees. Traders pay an access fee in SOL for each location. We share fees with stakeholders according to the validator commission rate. For example, if the validator charges an 8% commission and the total traders' fees are 100 SOL, the payouts will be 40 SOL to Triton One, 4.8 SOL to the validator, and 55.2 SOL to the stakeholders.
            </li>
            <li>
              In the future, we will participate in MEV (Maximum Extractable Value) programs offered by Jito Labs. Stakeholders will receive a portion of those fees too. Stay tuned to social media for more information from Jito Labs (https://www.jito.wtf/).
            </li>
          </ul>
        </div>
      </div>
      <hr class="w-full my-6 border-gray-300" />
    </div>
  </div>

  <div class="grid gap-24 lg:grid-cols-2 sm:mx-auto">
    <div>
      <h2>How does it work?</h2>
      <h3>Here is a simple diagram of a Professional Trading Center:</h3>
      <img src="./../assets/PTC_SolanaTradingdiagram.webp" alt="Diagram of how the Professional Trading Center works" />
      <span>
        Without retransmissions from the staked validators, un-staked RPC nodes are always the last to know about account state changes on the cluster. Colocation of all servers minimizes latency within the ecosystem.
      </span>
    </div>
    <div>
      <div
        class="p-6 transition-colors duration-200 transform rounded-lg bg-purple"
      >
        <h2 class="uppercase text-gray-100">Fees:</h2>
        <ul class="text-gray-200">
          <li>
            Trader Access Fee: 100 SOL per trader per location per month to split between Triton One & Validators/Delegators.
          </li>
          <li>
            Dedicated RPC Server(s):
            <ul class="ml-10">
              <li style="list-style: square;">
                Locations in Europe & North America: $2,000/server/month
              </li>
              <li style="list-style: square;">
                Locations in Tokyo, Hong Kong, or Singapore: $2,500/server/month
              </li>
              <li style="list-style: square;">
                Locations in Australia: $3,000/server/month
              </li>
            </ul>
          </li>
        </ul>

        <h2 class="uppercase text-gray-100 mt-4">Locations:</h2>
        <ul class="text-gray-200">
          <li>Now: Amsterdam, NL & Tokyo, JP</li>
          <li>Soon: Chicago, Dublin, London, New York, Washington DC</li>
        </ul>
      </div>
    </div>
  </div>

  <hr class="w-full my-6 border-gray-300" />

  <div class="grid gap-24 lg:grid-cols-2 sm:mx-auto">
    <div>
      <div
        class="p-6 transition-colors duration-200 transform rounded-lg bg-purple"
      >
        <h2 class="uppercase text-gray-100">Get Started!</h2>
        <ul class="text-gray-200">
          <li>
            Preview stage:
            <ul class="ml-10">
              <li style="list-style: square;">
                June 2022
              </li>
              <li style="list-style: square;">
                Stakeholders delegate SOL
              </li>
              <li style="list-style: square;">
                Retransmissions to RPC nodes begin
              </li>
              <li style="list-style: square;">
                No Trader Access Fees during this period! (RPC server fees apply)
              </li>
            </ul>
          </li>

          <li class="mb-6">
            Production stage:
            <ul class="ml-10">
              <li style="list-style: square;">
                July 2022 onward
              </li>
              <li style="list-style: square;">
                Amsterdam & Tokyo locations
              </li>
              <li style="list-style: square;">
                Additional data centers will come online with customer demand.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="pt-5">
        <router-link to="/contact">
          <button class="cta-btn-colored uppercase">Contact Us</button>
        </router-link>
      </div>
    </div>

    <div class="p-12">
      <img class="" src="./../assets/CatalystStaticpageimage.webp" alt="" />
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import Button from "./../components/Button.vue";
import Footer from "./../components/Footer.vue";
import Header from "./../components/Header.vue";
export default {
  setup() {
    useHead({
      title: "Triton: Fast hosted Solana RPC access",
      meta: [
        {
          name: "description",
          content: "Professional Trading Centers",
        },
      ],
    });
  },
  name: "Professional Trading Centers",
  components: {
    Button,
    Footer,
    Header,
  },
};
</script>
